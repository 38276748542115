<template>
  <div class="c-app modal-vue miner-info pools">
    <CWrapper class="bg-bg-gray pt-2">
      <div
        class="d-flex justify-content-between align-items-start mt-4 mb-2 c-miners-sort pr-0"
      >
        <div class="c-title pb-4">{{ $t("Worker") }}</div>
        <form class="c-miners-filtration d-flex">
          <CSelect :options="['10', '20', '30']" @update:value="updateValue" />
        </form>
      </div>
      <CRow>
        <CCol md="12" class="worker-mobile">
          <table class="table">
            <thead>
              <th>{{ $t("Username") }}</th>
              <th>{{ $t("Pool name") }}</th>
              <th>{{ $t("Password") }}</th>
              <th>{{ $t("Customer") }}</th>
              <th></th>
            </thead>
            <tbody>
              <tr v-for="(obj, i) in _workers.data" :key="i">
                <td>{{ obj.username }}</td>
                <td>{{ obj.pull.name }}</td>
                <td>{{ obj.password }}</td>
                <td>{{ obj.user.email }}</td>
                <td class="d-flex justify-content-around h-100">
                  <CButton
                    @click="
                      $store.commit('miners/triggerEdit', {
                        ...obj,
                        page: 'worker',
                        status: 'edit',
                      })
                    "
                    class="edit-icon border-none p-0"
                  >
                    <img
                      src="@/assets/icons/users/edit_icon.svg"
                      class="cursor-pointer m-0 p-0 btn"
                    />
                  </CButton>
                  <img
                    src="@/assets/icons/users/remove_icon.svg"
                    class="btn m-0 p-0"
                    @click="deleteWorker(obj)"
                  />
                </td>
              </tr>
            </tbody>
          </table>
          <CPagination
            :activePage.sync="page"
            :pages="
              $store.state.miners._workers.meta
                ? $store.state.miners._workers.meta.last_page
                : 0
            "
            :doubleArrows="false"
            @update:activePage="updatePage"
            size="lg"
            align="center"
          />
        </CCol>
      </CRow>
    </CWrapper>
    <CModal
      title="Delete"
      color="lightGray"
      class="text-main_black bg-milk_mix"
      :show.sync="errorModal"
    >
      <CForm>
        <div class="border-0 mb-0 text-center">
          <img src="@/assets/icons/ic_fail.svg" alt="" />
          <div class="mt-4">
            <p class="w-75 m-auto font-weight-normal">
              Connected worker can not be deleted
            </p>
          </div>
        </div>
      </CForm>
      <template #footer="{ item }">
        <span />
      </template>
    </CModal>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import request from "../../plugins/request";

export default {
  name: "Worker",
  components: {},
  data() {
    return {
      errorModal: false,
      items: [],
      page: parseInt(this.$route.params.id) ? parseInt(this.$route.params.id) : 1,
    };
  },
  created() {
    this.curPage();
    this.$store.dispatch("miners/GET_WORKER", {page: this.page ? this.page : 1, pageUrls : {...this.$route.query}});
  },
  computed: {
    ...mapState("miners", ["_workers", "allPools", "allCustomers"]),
  },
  methods: {
    ...mapActions("miners", ["GET_WORKER"]),
    curPage() {
      this.$emit("curPage", "worker");
    },
    deleteWorker(item) {
      let isSure = confirm("Are you sure?");
      if (!isSure) return;
      request
        .delete(`/api/workers/${item.id}`)
        .then(() => {
          this.$store.dispatch("miners/GET_WORKER", {page: this.page ? this.page : 1});
        })
        .catch((err) => {
          if (err.response.status == 500) {
            this.errorModal = true;
          }
        });
    },
    updateValue(value){
      const newObj = {...this.$route.query}
      newObj['per_page'] = value;
      this.GET_WORKER({page: this.page ? this.page : 1,pageUrls:  {
        ...newObj,
        per_page: value,
      }}).then(() => {
        this.$router.push({
          name: 'Worker',
          query: newObj
        })
      })
    },
    updatePage(e) {
      this.$route.params.id = e;
      this.$router.push({ ...this.$route });
    },
  },
};
</script>

<style scoped lang="sass">
.edit-icon:hover svg path
  fill: #0F5B84

.delete-icon:hover svg path
  fill: #EC5252
  stroke: #EC5252
</style>
